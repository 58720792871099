<template>
  <app-form-view
    app="region"
    model="subdivision"
    api-url="region/sub-division/"
    :title="$t('menu.subDivision')"
    :state="formData.state"
    :stateItems="stateItems"
    :approveItems="approveItems"
    v-model="formData"
    :prepare-data="prepareData"
  >
    <template v-slot="{ view }">
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="name"
            rules="required"
            :label="$t('fields.subDivisionName')"
            :view="view"
            v-model="formData.name"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveName"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_name"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="regionName"
            type="select-server"
            rules="required"
            :label="$t('fields.regionName')"
            :view="view"
            :binds="{
              apiUrl: 'region/region/?active=true&state=approved'
            }"
            v-model="formData.region_id"
            @input="getDivisionItem"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveRegionName"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_region_id"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="divisionName"
            type="select-server"
            rules="required"
            :label="$t('fields.divisionName')"
            :view="view"
            :disabled="!Boolean(formData.region_id)"
            :binds="{
              apiUrl: divisionUrl
            }"
            v-model="formData.division_id"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveDivisionName"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_division_id"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="12">
          <app-input
            name="active"
            type="switch"
            :label="$t('fields.active')"
            :view="view"
            v-model="formData.active"
          />
        </v-col>
      </v-row>
    </template>
  </app-form-view>
</template>

<script>
import AppInput from '@components/AppInput'
import AppFormView from '@components/AppFormView'

export default {
  name: 'sub-division-form',
  components: {
    AppInput,
    AppFormView
  },
  data() {
    return {
      formData: {
        state: 'draft'
      },
      approveItems: [
        'approve_name',
        'approve_region_id',
        'approve_division_id'
      ],
      divisionUrl: null
    }
  },
  computed: {
    stateItems() {
      return [
        { label: this.$t('label.draft'), value: 'draft' },
        { label: this.$t('label.sentApprove'), value: 'sent_to_approve' },
        { label: this.$t('label.approve'), value: 'approved' }
      ]
    },
    isSentToApprove() {
      return this.formData.state === 'sent_to_approve'
    }
  },
  created() {
    this.$store.dispatch('addBreadcrumb', {
      name: 'subDivisionCreate'
    })
  },
  watch: {
    'formData.name': function (value) {
      if (this.$route.name === 'subDivisionEdit') {
        this.$store.dispatch('addBreadcrumb', {
          name: 'subDivisionEdit',
          text: value ?? 'N/A',
          to: {
            name: 'subDivisionEdit',
            params: { id: this.$route.params.id }
          }
        })
      }
    }
  },
  methods: {
    prepareData(data) {
      data.region_id = data.region_id.id
      data.division_id = data.division_id.id
      return data
    },
    getDivisionItem() {
      var url = ''
      if (
        this.formData.region_id !== null &&
        this.formData.region_id !== undefined
      ) {
        url =
          'region/division/?active=true&state=approved&region_id=' +
          this.formData.region_id.id
      } else {
        url = 'region/division/?active=true&state=approved'
      }

      this.divisionUrl = url
    }
  }
}
</script>
